<script setup lang="ts">
import type { Product } from '@shared/types/models'
import { trans } from 'laravel-vue-i18n'
import { useCartStore } from '@/stores/cart'
import ShoppingBagIcon from '@shared/components/Icons/ShoppingBagIcon.vue'
import BigButton from '@shared/components/Buttons/BigButton.vue'
import showToast from '@shared/modules/showToast'

const props = defineProps<{
    product: Product
    categorySlug: string
}>()

const store = useCartStore()

function addToCart(): void {
    store.toCart(props.product, props.categorySlug)
    store.fetchCartItems()
    showToast({ text: trans('product.added_to_bag') })
}
</script>

<template>
    <big-button @click="addToCart" :disabled="product.status === 'hidden'">
        <shopping-bag-icon class="w-5 h-5" />
        {{ trans('main.buy') }}
    </big-button>
</template>
