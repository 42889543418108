<script setup lang="ts">
import { Attachment, Category, type Product } from '@shared/types/models'
import { computed } from 'vue'
import { trans } from 'laravel-vue-i18n'
import CloseIcon from '@shared/components/Icons/CloseIcon.vue'
import SquareButton from '@shared/components/Buttons/SquareButton.vue'

const emit = defineEmits<{
    (e: 'remove'): void
}>()

const props = defineProps<{
    product: Product
}>()

const image = computed<Attachment | null>(() => {
    if (!props.product.attachments) {
        return null
    }

    return props.product.attachments.find(a => a.type === 'image') || null
})

const category = computed<Category>(() => {
    return props.product.categories![0]
})

const src = computed<string>(() => {
    if (!image.value) {
        return '/storage/products/sm/default.webp'
    }

    return `/storage/products/sm/${image.value.name}`
})
</script>

<template>
    <a
        :href="`/${category.slug}/${product.slug}`"
        :class="[
            'shadow-sm border border-border-light py-2 pl-2 pr-4 flex gap-4',
            'transition-colors hover:bg-gray-50 justify-between bg-white',
            product.status === 'hidden' && 'opacity-80',
            'items-center rounded-sm',
        ]"
    >
        <div class="flex gap-4 items-center">
            <img :src class="rounded-sm" width="50" />

            <div>
                <h2 class="text-md md:text-lg !leading-5">
                    {{ product.name[$locale] }}
                </h2>

                <b class="font-heading">{{ product.price }}.00 ₴</b>

                <h3 v-if="product.status === 'hidden'" class="text-red-700">
                    {{ trans('product.not_in_stock') }}
                </h3>
            </div>
        </div>

        <square-button
            @click.prevent="emit('remove')"
            :icon="CloseIcon"
            iconClassName="text-gray-600"
            class="border-0"
        />
    </a>
</template>
