<script setup lang="ts">
import type { Category } from '@shared/types/models'
import { computed, onMounted } from 'vue'
import { debounce } from 'lodash'
import { useCategoryStore } from '@/stores/category'
import isDesktopScreen from '@shared/modules/isDesktopScreen'
import CategoryItem from '@/components/Navbar/CategoriesBar/CategoryItem.vue'
import AppearTransition from '@shared/components/Transitions/AppearTransition.vue'
import CategoryMenu from '@/components/Navbar/CategoriesBar/CategoryMenu.vue'
import isTabletScreen from '@shared/modules/isTabletScreen'

const store = useCategoryStore()

onMounted(() => {
    window.addEventListener('resize', () => {
        store.selectedCategory = null
    })
})

const showMenu = computed(() => {
    return store.selectedCategory && !isTabletScreen()
})

const selectCategory = debounce((category: Category) => {
    if (!category.categories || category.categories.length === 0) {
        store.selectedCategory = null
        return
    }

    store.selectedCategory = category
}, 300)
</script>

<template>
    <nav class="bg-nav-second text-white h-12 relative z-30 hidden xl:block">
        <div class="container h-full">
            <appear-transition>
                <div v-if="!store.loading" class="h-full">
                    <category-item
                        v-for="category in store.categories"
                        :key="category.id"
                        :category
                        :selected="store.selectedCategory?.id === category.id"
                        @hovered="selectCategory(category)"
                    />
                </div>
            </appear-transition>

            <category-menu v-if="showMenu" />
        </div>
    </nav>
</template>
