<script setup lang="ts">
import type { Order } from '@shared/types/models'
import { trans } from 'laravel-vue-i18n'
import { ref, onMounted } from 'vue'
import axios from 'axios'
import handleError from '@/modules/handleError'
import Message from '@shared/components/Message.vue'
import Spinner from '@shared/components/Spinner.vue'

type Props = {
    orderSlug: string
}

const props = defineProps<Props>()

const order = ref<Order | null>(null)
const loading = ref<boolean>(false)

onMounted(fetchOrder)

async function fetchOrder(): Promise<void> {
    loading.value = true

    try {
        const resp = await axios.get<Order>(`/api/orders/${props.orderSlug}`)
        order.value = resp.data
    } catch (e) {
        handleError(e)
    }

    loading.value = false
}
</script>

<template>
    <div class="absolute top-1/2 left-5 right-5 -translate-y-1/2">
        <spinner v-if="loading" />

        <message v-else-if="orderSlug === '' || !order">
            {{ trans('main.order_not_found') }}
        </message>

        <div v-else class="text-center">
            <h2 class="text-2xl md:text-4xl font-heading">
                {{ trans('main.your_order_is_number', { n: order.id.toString() }) }}
            </h2>

            <h3 class="text-xl md:text-2xl space-x-2">
                <span>{{ trans('main.order_status') }}:</span>

                <b v-if="order.status" class="text-primary">
                    {{ order.status.name[$locale] }}
                </b>
            </h3>
        </div>
    </div>
</template>
