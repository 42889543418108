<script setup lang="ts">
import type { PaymentOption } from '@shared/types'
import { trans } from 'laravel-vue-i18n'
import { useCheckoutStore } from '@/stores/checkout'
import TextareaField from '@shared/components/Form/TextareaField.vue'
import Block from '@/components/Checkout/Blocks/Block.vue'
import CheckoutSection from '@/components/Checkout/CheckoutSection.vue'

const store = useCheckoutStore()
</script>

<template>
    <checkout-section :label="trans('main.comment')">
        <block>
            <textarea-field
                id="comment"
                name="comment"
                :label="trans('main.comment_for_order')"
                :placeholder="trans('main.comment_for_order')"
                v-model="store.formData.comment"
                class="resize-none"
            />
        </block>
    </checkout-section>
</template>
