<script setup lang="ts">
import LabelElem from '@shared/components/Form/LabelElem.vue'

defineProps<{
    label: string
    id: string
    name: string
    value: string
    required?: boolean
    class?: string
}>()

const modelValue = defineModel()
</script>

<template>
    <div class="flex gap-4">
        <input
            class=""
            v-model="modelValue"
            type="radio"
            :value
            :class
            :id
            :name
            :required
        />

        <label-elem :id :label :required />
    </div>
</template>
