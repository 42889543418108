import type { CartItem, CartItemDetails } from '@shared/types'
import type { Product } from '@shared/types/models'
import { ref, computed, onMounted } from 'vue'
import { defineStore } from 'pinia'
import axios from 'axios'
import handleError from '@/modules/handleError'
import {
    getCartItems,
    getCartItem,
    addToCart,
    removeFromCart,
    clearCart,
    updateQuantity,
    save,
} from '@/modules/storage/cartStorage'

export const useCartStore = defineStore('cart', () => {
    const cartItems = ref<CartItem[]>([])
    const isOpen = ref<boolean>(false)
    const loading = ref<boolean>(true)

    onMounted(fetchCartItemsFromStorage)

    const quantity = computed<number>(() =>
        cartItems.value.reduce((acc, item) => acc + item.quantity, 0),
    )

    const totalPrice = computed<number>(() => {
        return cartItems.value.reduce((acc, item) => {
            if (!item.price) {
                return 0
            }

            return acc + item.price * item.quantity
        }, 0)
    })

    function fetchCartItemsFromStorage(): void {
        cartItems.value = getCartItems()
    }

    async function fetchCartItems(): Promise<void> {
        fetchCartItemsFromStorage()

        const slugs = cartItems.value.map(item => item.slug).join(',')
        const uri = `/api/products/details/${slugs}`

        try {
            const resp = await axios.get<CartItemDetails[]>(uri)
            applyDetailsToCartItems(resp.data)
        } catch (e) {
            handleError(e, 6000)
        }

        loading.value = false
    }

    function updateItemQuantity(slug: string, amount: number): void {
        updateQuantity(slug, amount)

        cartItems.value = cartItems.value.map(item => {
            if (item.slug === slug) {
                item.quantity = amount
            }

            return item
        })
    }

    function applyDetailsToCartItems(details: CartItemDetails[]): void {
        for (const item of cartItems.value) {
            const detail = details.find(d => d.slug === item.slug)

            if (!detail) {
                console.error(`No details found for ${item.slug}`)
                continue
            }

            item.price = detail.price
            item.notAvailable = detail.status === 'hidden'
            item.min_order_quantity = detail.min_order_quantity
        }

        save(cartItems.value)
    }

    function toCart(product: Product, categorySlug: string): void {
        const firstImage = product.attachments?.find(a => a.type === 'image')?.name
        const imageName = firstImage || 'default.webp'
        let quantity = 1

        const oldCartItem = getCartItem(product.slug)

        if (product.min_order_quantity > 1 && !oldCartItem) {
            quantity = product.min_order_quantity
        }

        addToCart({
            slug: product.slug,
            name: product.name,
            imageName,
            quantity,
            uri: `/${categorySlug}/${product.slug}`,
            min_order_quantity: product.min_order_quantity,
            price: product.price,
        })
    }

    function removeItem(slug: string): void {
        removeFromCart(slug)
        fetchCartItems()
    }

    function clear(): void {
        clearCart()
        cartItems.value = []
    }

    return {
        totalPrice,
        cartItems,
        isOpen,
        quantity,
        loading,
        clear,
        removeItem,
        toCart,
        fetchCartItems,
        updateItemQuantity,
    }
})
