<script setup lang="ts">
type Emits = {
    (e: 'closed'): void
    (e: 'hovered'): void
}

const emit = defineEmits<Emits>()
</script>

<template>
    <div
        @click="emit('closed')"
        @mouseover="emit('hovered')"
        class="bg-black/50 inset-0 fixed z-40"
    ></div>
</template>
