<script setup lang="ts">
import type { Category } from '@shared/types/models'
import { ref } from 'vue'
import linkTo from '@shared/modules/linkTo'
import NestedMobileMenu from '@/components/MobileMenu/NestedMobileMenu.vue'
import SlideFromRightTransition from '@shared/components/Transitions/SlideFromRightTransition.vue'

const props = defineProps<{
    category: Category
}>()

const emit = defineEmits<{
    (e: 'closeMenu'): void
}>()

const isOpen = ref<boolean>(false)

const className = 'flex items-center gap-4  p-2 text-lg leading-5 text-left'
const imageSrc = `/storage/categories/square/${props.category.navbar_image_name}`
</script>

<template>
    <div class="border-b border-border-light last:border-none">
        <button
            v-if="category.categories && category.categories.length > 0"
            @click="isOpen = true"
            type="button"
            :class="className"
        >
            <img :src="imageSrc" class="rounded-full w-10 h-10" />
            <span>{{ category.name[$locale] }}</span>
        </button>

        <a
            v-else
            @click="emit('closeMenu')"
            :href="linkTo(category.slug)"
            :class="className"
        >
            <img :src="imageSrc" class="rounded-full w-10 h-10" />
            <span>{{ category.name[$locale] }}</span>
        </a>

        <slide-from-right-transition>
            <nested-mobile-menu
                v-if="
                    category.categories && category.categories.length > 0 && isOpen
                "
                :category="category"
                :categories="category.categories"
                class="!absolute"
                @closeMenu="isOpen = false"
            />
        </slide-from-right-transition>
    </div>
</template>
