<script setup lang="ts">
import type { ReviewForm } from '@shared/types'
import { ref, computed } from 'vue'
import { trans } from 'laravel-vue-i18n'
import showToast from '@shared/modules/showToast'
import axios from 'axios'
import ChooseStars from '@/components/Products/ProductTabs/ChooseStars.vue'
import BigButton from '@shared/components/Buttons/BigButton.vue'
import Spinner from '@shared/components/Spinner.vue'
import WhiteButton from '@shared/components/Buttons/WhiteButton.vue'
import Popup from '@shared/components/Popup.vue'
import PencilIcon from '@shared/components/Icons/PencilIcon.vue'
import CogIcon from '@shared/components/Icons/CogIcon.vue'
import EnvelopeIcon from '@shared/components/Icons/EnvelopeIcon.vue'

const props = defineProps<{
    productSlug: string
}>()

const inpClass = 'w-full border border-border rounded-md py-2 px-3'
const loading = ref<boolean>(false)
const formIsVisible = ref<boolean>(false)

const form = ref<ReviewForm>({
    name: '',
    content: '',
    rating: 5,
    slug: props.productSlug,
})

const allowedToSubmit = computed<boolean>(() => {
    return form.value.name.length > 2 && form.value.rating > 0
})

async function submitNewReview(): Promise<void> {
    if (!allowedToSubmit.value || loading.value) {
        return
    }

    loading.value = true

    const resp = await axios.post<string>('/api/reviews', form.value)

    formIsVisible.value = false
    loading.value = false

    showToast({ text: resp.data })
}
</script>

<template>
    <div class="flex gap-3">
        <white-button @click="formIsVisible = true">
            <envelope-icon class="size-5" />
            {{ trans('product.leave_a_review') }}
        </white-button>

        <white-button v-if="$auth" href="/admin/reviews">
            <cog-icon class="size-5" />
            {{ trans('product.manage_reviews') }}
        </white-button>
    </div>

    <!-- Review Form Modal -->
    <popup
        v-if="formIsVisible"
        @closed="formIsVisible = false"
        :label="trans('product.leave_a_review')"
        className="w-full max-w-[600px]"
    >
        <form @submit.prevent="submitNewReview" class="space-y-4">
            <div>
                <choose-stars
                    @chosen="rate => (form.rating = rate)"
                    :default-rate="form.rating"
                />
            </div>

            <div>
                <label for="review-name">
                    {{ trans('main.your_name') }}
                </label>

                <input
                    id="review-name"
                    type="text"
                    name="name"
                    :placeholder="trans('main.enter_your_name')"
                    :class="inpClass"
                    v-model="form.name"
                />
            </div>

            <div>
                <label for="review-comment">
                    {{ trans('product.your_review') }}
                </label>

                <textarea
                    id="review-comment"
                    name="name"
                    :placeholder="trans('main.enter_your_review')"
                    :class="inpClass + ' min-h-[150px]'"
                    v-model="form.content"
                ></textarea>
            </div>

            <div class="flex items-center gap-5">
                <big-button
                    type="submit"
                    :disabled="!allowedToSubmit || loading"
                    :class="
                        !allowedToSubmit || loading
                            ? 'opacity-50 cursor-not-allowed'
                            : ''
                    "
                >
                    <pencil-icon class="size-5 mr-2" />
                    {{ trans('product.leave_a_review') }}
                </big-button>

                <div v-if="loading" class="flex items-center gap-4">
                    <spinner />
                    <span>{{ trans('main.processing') }}...</span>
                </div>
            </div>
        </form>
    </popup>
</template>
