<script setup lang="ts">
import type { Category } from '@shared/types/models'
import { useCategoryStore } from '@/stores/category'
import linkTo from '@shared/modules/linkTo'

defineProps<{
    category: Category
    index: number
}>()

const store = useCategoryStore()

function isLastInColumn(index: number): boolean {
    if (!store.selectedCategory || !store.selectedCategory.categories) {
        return false
    }

    const columns = 3
    const items = store.selectedCategory.categories
    const nextRowItemIndex = index + columns

    return nextRowItemIndex >= items.length || items[nextRowItemIndex] === undefined
}
</script>

<template>
    <a
        :href="linkTo(category.slug)"
        class="hover:text-link flex items-center gap-3 group w-full h-14"
    >
        <img
            :src="`/storage/categories/square/${category.navbar_image_name}`"
            class="rounded-full w-9 h-9 border border-border group-hover:border-link"
        />

        <p
            :class="[
                'flex-1 pb-2 pt-1 h-14 flex items-center pl-1 leading-5',
                isLastInColumn(index) ? 'border-b-none' : 'border-b border-border',
            ]"
        >
            {{ category.name[$locale] }}
        </p>
    </a>
</template>
