<script setup lang="ts">
import { ref } from 'vue'
import { trans } from 'laravel-vue-i18n'
import ArrowLeftIcon from '@shared/components/Icons/ArrowLeftIcon.vue'

const props = defineProps<{
    targetId: string
}>()

const buttonIsVisible = ref<boolean>(true)

function showFullDescription(): void {
    const elem = document.getElementById(props.targetId)

    if (!elem) {
        return
    }

    buttonIsVisible.value = false
    elem.classList.remove('max-h-[500px]')
    elem.classList.add('after:hidden')
}
</script>

<template>
    <div class="text-center">
        <button
            v-if="buttonIsVisible"
            @click="showFullDescription"
            type="button"
            class="mx-auto mt-7 text-xl hover:text-primary flex gap-2 items-center"
        >
            {{ trans('main.read_all') }}
            <arrow-left-icon class="w-4 h-4 -rotate-90" />
        </button>
    </div>
</template>
