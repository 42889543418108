import type { Category } from '@shared/types/models'
import { ref, onMounted } from 'vue'
import { defineStore } from 'pinia'
import axios from 'axios'
import handleError from '@admin/modules/handleError'

export const useCategoryStore = defineStore('category', () => {
    const categories = ref<Category[]>([])
    const loading = ref<boolean>(false)
    const selectedCategory = ref<Category | null>(null)

    onMounted(fetchCategories)

    async function fetchCategories(): Promise<void> {
        loading.value = true

        const url = '/api/categories'

        try {
            const resp = await axios.get<Category[]>(url)
            categories.value = resp.data
        } catch (e) {
            handleError(e)
        }

        loading.value = false
    }

    return {
        selectedCategory,
        categories,
        loading,
    }
})
