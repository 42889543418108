import type { Pagination } from '@shared/types/response'
import type { Product } from '@shared/types/models'
import { ref } from 'vue'
import { defineStore } from 'pinia'
import axios from 'axios'
import handleError from '@/modules/handleError'

export const useProductStore = defineStore('product', () => {
    const products = ref<Product[]>([])
    const loading = ref<boolean>(true)
    const loadingMore = ref<boolean>(false)
    const nextPageUrl = ref<string | null>(null)

    async function fetchProducts(url: string, refreshList = true): Promise<void> {
        loadingMore.value = !refreshList
        loading.value = refreshList

        try {
            const resp = await axios.get<Pagination<Product[]>>(url)

            if (refreshList) {
                products.value = resp.data.data
            } else {
                products.value.push(...resp.data.data)
            }

            nextPageUrl.value = resp.data.next_page_url
        } catch (err) {
            handleError(err)
        }

        loadingMore.value = false
        loading.value = false
    }

    function loadMoreProducts(): void {
        if (!nextPageUrl.value) {
            return
        }

        fetchProducts(nextPageUrl.value, false)
    }

    return {
        products,
        loading,
        nextPageUrl,
        loadingMore,
        loadMoreProducts,
        fetchProducts,
    }
})
