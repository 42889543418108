<script setup lang="ts">
import type { Product } from '@shared/types/models'
import ChevronRightIcon from '@shared/components/Icons/ChevronRightIcon.vue'

const emit = defineEmits<{
    (e: 'prev'): void
    (e: 'next'): void
}>()

defineProps<{
    product: Product
}>()

const styles = `filter: drop-shadow(2px 0 0 white) drop-shadow(-2px 0 0 white)`
</script>

<template>
    <div class="hidden md:block">
        <button @click="emit('prev')" type="button" class="absolute top-1/2 left-2">
            <chevron-right-icon class="w-16 h-16 rotate-180" :style="styles" />
        </button>

        <button @click="emit('next')" type="button" class="absolute top-1/2 right-2">
            <chevron-right-icon class="w-16 h-16" :style="styles" />
        </button>
    </div>
</template>
