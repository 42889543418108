<script setup lang="ts">
import { useCategoryStore } from '@/stores/category'
import Overlay from '@shared/components/Overlay.vue'
import CategoryMenuItem from '@/components/Navbar/CategoriesBar/CategoryMenuItem.vue'

const store = useCategoryStore()
</script>

<template>
    <teleport to="body">
        <div class="container">
            <overlay @hovered="store.selectedCategory = null" class="!z-20" />

            <div
                v-if="
                    store.selectedCategory?.categories &&
                    store.selectedCategory.categories.length > 0
                "
                :class="[
                    'absolute bg-page-second text-font p-7 top-[132px] z-20 inset-x-0',
                    'w-[1248px] 2xl:w-[1504px] 3xl:w-[1688px] mx-auto grid grid-cols-4 gap-x-5 max-h-[400px]',
                ]"
            >
                <category-menu-item
                    v-for="(category, index) in store.selectedCategory!.categories!"
                    :key="category.id"
                    :index
                    :category
                />
            </div>
        </div>
    </teleport>
</template>
