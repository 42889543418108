<script setup lang="ts">
import CloseIcon from '@shared/components/Icons/CloseIcon.vue'

type Emits = {
    (e: 'close'): void
}

const emit = defineEmits<Emits>()
</script>

<template>
    <button
        @click="emit('close')"
        type="button"
        :class="[
            'fixed left-[var(--sidebar-menu-width)] ml-1 top-0.5 text-black text-xl',
            'p-1.5 rounded-sm outline outline-blue-500 border border-white',
            '-outline-offset-2',
        ]"
    >
        <close-icon class="w-9 h-9 text-white" />
    </button>
</template>
