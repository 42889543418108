import showToast from '@shared/modules/showToast'
import { trans } from 'laravel-vue-i18n'

export default (err: any, duration?: number) => {
    console.error(err)

    if (err && err.response && err.response.data) {
        const errors = err.response.data.errors

        if (errors) {
            let text = ''

            for (const key in errors) {
                if (errors.hasOwnProperty(key)) {
                    text += `- ${errors[key][0]}\n`
                }
            }

            showToast({ text, success: false, duration })
            return
        }

        if (err.response.data.message && !err.response.data.exception) {
            showToast({ text: err.response.data.message, success: false, duration })
            return
        }
    }

    showToast({ text: trans('main.server_error'), success: false, duration })
}
