<script setup lang="ts">
import { useSearchStore } from '@/stores/search'
import Search from '@/components/Navbar/Search/Search.vue'

const searchStore = useSearchStore()
</script>

<template>
    <aside
        class="fixed inset-y-0 left-0 right-20 bg-page-second z-50 p-3 shadow-lg xl:hidden"
    >
        <div class="text-center">
            <search class="mx-auto" :withOverlay="false" />
        </div>
    </aside>
</template>
