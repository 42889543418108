<script setup lang="ts">
import type { CategoryBanner, Category } from '@shared/types/models'
import { ref, computed, onMounted, onUnmounted } from 'vue'
import ArrowLeftIcon from '@shared/components/Icons/ArrowLeftIcon.vue'

const props = defineProps<{
    category: Category
}>()

const index = ref<number>(0)
const intervalId = ref<number | null>(null)
const className = '-translate-y-1/2 p-2 absolute top-1/2 bg-white/30 rounded-lg'

const banner = computed<CategoryBanner>(() => {
    return props.category.banners![index.value]
})

const hasManyBanners = computed(() => props.category.banners!.length > 1)

onMounted(() => {
    intervalId.value = window.setInterval(switchNext, 3000)
})

onUnmounted(() => {
    if (intervalId.value) {
        window.clearInterval(intervalId.value)
    }
})

function switchNext(): void {
    if (index.value === props.category.banners!.length - 1) {
        index.value = 0
        return
    }

    index.value++
}

function switchPrev(): void {
    if (index.value === 0) {
        index.value = props.category.banners!.length - 1
        return
    }

    index.value--
}
</script>

<template>
    <a :href="banner.url || '#'" class="bg-page py-3 relative block">
        <button
            v-if="hasManyBanners"
            @click="switchPrev"
            type="button"
            class="left-1"
            :class="className"
        >
            <arrow-left-icon class="w-4 h-4" />
        </button>

        <img
            :src="`/storage/category-banners/${banner.image_name}`"
            class="w-full"
        />

        <button
            v-if="hasManyBanners"
            @click="switchNext"
            type="button"
            class="right-1"
            :class="className"
        >
            <arrow-left-icon class="w-4 h-4 rotate-180" />
        </button>
    </a>
</template>
