<script setup lang="ts">
defineProps<{
    id: string
}>()

const modelValue = defineModel()
</script>

<template>
    <div class="flex items-center gap-2">
        <input type="checkbox" name="remember" :id v-model="modelValue" />

        <label :for="id">
            <slot />
        </label>
    </div>
</template>
