<script setup lang="ts">
import type { Category } from '@shared/types/models'
import linkTo from '@shared/modules/linkTo'

const emit = defineEmits<{
    (e: 'hovered'): void
}>()

defineProps<{
    category: Category
    selected: boolean
}>()
</script>

<template>
    <li class="inline-block h-full">
        <a
            @mouseover="emit('hovered')"
            :href="linkTo(category.slug)"
            :class="[
                'px-2 hover:bg-gray-200 h-full inline-flex hover:text-font',
                'items-center text-sm',
                selected ? 'bg-gray-200 text-font' : 'text-white',
            ]"
        >
            {{ category.name[$locale] }}
        </a>
    </li>
</template>
