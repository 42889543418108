<script setup lang="ts">
import { ref } from 'vue'
import NavButton from '@/components/Navbar/NavButton.vue'
import LikeUkraineIcon from '@shared/components/Icons/LikeUkraineIcon.vue'
import WishlistPopup from '@/components/Wishlist/WishlistPopup.vue'

const showPopup = ref<boolean>(false)
</script>

<template>
    <nav-button
        :icon="LikeUkraineIcon"
        class="flex items-center"
        iconClassName="translate-y-1"
        @click="showPopup = true"
    />

    <teleport to="body">
        <wishlist-popup v-if="showPopup" @closed="showPopup = false" />
    </teleport>
</template>
