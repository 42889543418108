<script setup lang="ts">
import { Attachment, type Product } from '@shared/types/models'
import { computed } from 'vue'
import { useImageSwitcherStore } from '@/stores/imageSwitcher'
import { trans } from 'laravel-vue-i18n'
import PlayIcon from '@shared/components/Icons/PlayIcon.vue'

const props = defineProps<{
    product: Product
}>()

const store = useImageSwitcherStore(props.product)()

const videoAttachment = computed<Attachment | null>(() => {
    if (!props.product.attachments) {
        return null
    }

    return (
        props.product.attachments.find(attachment => attachment.type === 'video') ||
        null
    )
})

async function toggleVideo(): Promise<void> {
    if (!videoAttachment.value) {
        return
    }

    const videoUrl = `/storage/products/videos/${videoAttachment.value.name}`
    const videoElem = generateVideoElement(videoUrl)

    // Function to remove video and clean up
    const cleanupVideo = () => {
        videoElem.pause()
        videoElem.src = ''
        videoElem.remove()
        document.removeEventListener('webkitendfullscreen', cleanupVideo)
        document.removeEventListener('fullscreenchange', cleanupVideo)
    }

    // Listen for iOS-specific event
    videoElem.addEventListener('webkitendfullscreen', cleanupVideo)

    // Listen for visibility change (helps with iOS)
    document.addEventListener('visibilitychange', cleanupVideo)

    document.body.appendChild(videoElem)

    if ('webkitEnterFullscreen' in videoElem) {
        await videoElem.play()
        await (videoElem as any).webkitEnterFullscreen()
        videoElem.addEventListener('webkitendfullscreen', cleanupVideo)
    } else {
        await videoElem.requestFullscreen()

        setTimeout(
            () => videoElem.addEventListener('fullscreenchange', cleanupVideo),
            300,
        )
    }
}

function generateVideoElement(videoUrl: string): HTMLVideoElement {
    const video = document.createElement('video')
    video.src = videoUrl
    video.controls = true
    video.autoplay = true
    video.loop = true

    video.style.width = '100%'
    video.style.height = '100%'
    video.style.position = 'fixed'
    video.style.top = '0'
    video.style.left = '0'
    video.style.zIndex = '9999'

    return video
}
</script>

<template>
    <button
        v-if="videoAttachment"
        type="button"
        class="bg-page-second w-full py-4 px-4 md:hidden"
        @click="toggleVideo"
    >
        <span class="flex items-center justify-center gap-2">
            <play-icon class="w-6 h-6" stroke-width="2" />

            <span class="font-heading font-bold tracking-widest uppercase">
                <span>{{ trans('main.video') }}</span>
            </span>
        </span>
    </button>
</template>
