<script setup lang="ts">
import { nextTick } from 'vue'
import { useSearchStore } from '@/stores/search'
import NavButton from '@/components/Navbar/NavButton.vue'
import SearchIcon from '@shared/components/Icons/SearchIcon.vue'

const store = useSearchStore()

async function openSearch(): Promise<void> {
    store.isOpen = true
    await nextTick()
    store.input?.focus()
}
</script>

<template>
    <nav-button @click="openSearch" :icon="SearchIcon" class="block xl:hidden" />
</template>
