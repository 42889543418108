<script setup lang="ts">
import type { DeliveryOption } from '@shared/types'
import { trans } from 'laravel-vue-i18n'
import { useCheckoutStore } from '@/stores/checkout'
import RadioField from '@shared/components/Form/RadioField.vue'
import Block from '@/components/Checkout/Blocks/Block.vue'
import CheckoutSection from '@/components/Checkout/CheckoutSection.vue'

const deliveries: DeliveryOption[] = [
    {
        title: 'main.delivery_at_post_rates',
        value: 'warehouse',
    },
    {
        title: 'main.address_delivery_by_courier',
        value: 'address',
    },
]

const store = useCheckoutStore()
</script>

<template>
    <checkout-section :label="trans('main.delivery_way')">
        <block>
            <div class="space-y-1">
                <radio-field
                    v-for="delivery in deliveries"
                    :key="delivery.value"
                    :id="delivery.value"
                    name="delivery"
                    :label="trans(delivery.title)"
                    v-model="store.formData.delivery"
                    :value="delivery.value"
                />
            </div>
        </block>
    </checkout-section>
</template>
