<script setup lang="ts">
import { ref, watchEffect } from 'vue'

type Props = {
    disabled?: boolean
    href?: string
}

const props = defineProps<Props>()

const initialClasses =
    'flex items-center gap-2.5 bg-white py-1.5 px-4 border border-gray-400 text-gray-500 rounded-lg hover:border-gray-500 hover:text-gray-700'
const classes = ref<string>(initialClasses)

watchEffect(() => {
    if (props.disabled) {
        classes.value += ' opacity-70 cursor-not-allowed'
    } else {
        classes.value = initialClasses
    }
})
</script>

<template>
    <a v-if="href" :href :class="classes">
        <slot />
    </a>

    <button v-else type="button" :class="classes">
        <slot />
    </button>
</template>
