<script setup lang="ts">
import { trans } from 'laravel-vue-i18n'
import { useCartStore } from '@/stores/cart'
import CartItem from '@/components/Cart/CartItem.vue'
import CartTotal from '@/components/Cart/CartTotal.vue'
import CheckoutSection from '@/components/Checkout/CheckoutSection.vue'

const cartStore = useCartStore()
</script>

<template>
    <div class="relative">
        <checkout-section
            class="lg:sticky top-2"
            :label="
                trans('product.n_products', {
                    n: cartStore.quantity.toString(),
                })
            "
        >
            <template #actions>
                <button
                    @click="cartStore.isOpen = true"
                    type="button"
                    class="hover:underline"
                >
                    {{ trans('main.edit') }}
                </button>
            </template>

            <ul class="max-h-[320px] overflow-y-auto">
                <cart-item
                    v-for="item in cartStore.cartItems"
                    :key="item.slug"
                    :item
                    class="px-0"
                    :editable="false"
                />
            </ul>

            <cart-total :withFooter="false" />
        </checkout-section>
    </div>
</template>
