<script setup lang="ts">
import type { CartItem } from '@shared/types'
import { trans } from 'laravel-vue-i18n'
import { useLikeButton } from '@/composables/useLikeButton'
import HeartIcon from '@shared/components/Icons/HeartIcon.vue'

const props = defineProps<{
    cartItem: CartItem
}>()

const { like, liked } = useLikeButton(props.cartItem.slug)
</script>

<template>
    <button
        @click.prevent="like"
        class="py-1 text-font-second text-sm flex items-center gap-2 hover:text-primary"
    >
        <heart-icon class="w-4 h-4" :class="{ 'fill-black': liked }" />
        {{ trans('product.save_for_later') }}
    </button>
</template>
