const KEY = 'wishlist'

export function getWishlist(): string[] {
    return JSON.parse(localStorage.getItem(KEY) || '[]')
}

export function addToWishlist(slug: string): void {
    const wishlist = getWishlist()
    wishlist.push(slug)
    save(wishlist)
}

export function removeFromWishlist(slug: string): void {
    const wishlist = getWishlist()
    const index = wishlist.indexOf(slug)

    if (index !== -1) {
        wishlist.splice(index, 1)
        save(wishlist)
    }
}

export function isWishlisted(slug: string): boolean {
    return getWishlist().includes(slug)
}

function save(wishlist: string[]): void {
    localStorage.setItem(KEY, JSON.stringify(wishlist))
}
