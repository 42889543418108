import { Option } from '@shared/types/models'
import { ref, onMounted } from 'vue'
import handleError from '@/modules/handleError'
import { defineStore } from 'pinia'
import axios from 'axios'

export const useOptionStore = defineStore('option', () => {
    const options = ref<Option[]>([])
    const loading = ref<boolean>(true)

    onMounted(fetchOptions)

    async function fetchOptions(): Promise<void> {
        loading.value = true

        try {
            const resp = await axios.get<Option[]>(`/api/options`)

            if (resp.data) {
                options.value = resp.data
            }
        } catch (e) {
            handleError(e)
        }

        loading.value = false
    }

    return {
        options,
        loading,
    }
})
