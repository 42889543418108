import { ref } from 'vue'
import { defineStore } from 'pinia'

export const useMenuStore = defineStore('menu', () => {
    const isOpen = ref(false)

    return {
        isOpen,
    }
})
