<script setup lang="ts">
import { useMenuStore } from '@/stores/menu'
import NavButton from '@/components/Navbar/NavButton.vue'
import MenuIcon from '@shared/components/Icons/MenuIcon.vue'

const menuStore = useMenuStore()
</script>

<template>
    <nav-button
        @click="menuStore.isOpen = true"
        :icon="MenuIcon"
        class="xl:hidden"
        iconClassName="!w-7 !h-7"
    />
</template>
