<script setup lang="ts">
import type { PaymentOption } from '@shared/types'
import { trans } from 'laravel-vue-i18n'
import { useCheckoutStore } from '@/stores/checkout'
import RadioField from '@shared/components/Form/RadioField.vue'
import Block from '@/components/Checkout/Blocks/Block.vue'
import CheckoutSection from '@/components/Checkout/CheckoutSection.vue'

const payments: PaymentOption[] = [
    // {
    //     title: 'main.private_bank_payment',
    //     value: 'bank_transfer',
    // },
    {
        title: 'main.payment_when_receive',
        value: 'cod',
    },
]

const store = useCheckoutStore()
</script>

<template>
    <checkout-section :label="trans('main.payment_choice')">
        <block>
            <div class="space-y-1">
                <radio-field
                    v-for="payment in payments"
                    :key="payment.value"
                    :id="payment.value"
                    name="payment"
                    :label="trans(payment.title)"
                    v-model="store.formData.payment"
                    :value="payment.value"
                />
            </div>
        </block>
    </checkout-section>
</template>
