<script setup lang="ts">
import type { Product } from '@shared/types/models'
import { TabGroup, TabList, Tab, TabPanels, TabPanel } from '@headlessui/vue'
import { trans } from 'laravel-vue-i18n'
import { useProductTabsStore } from '@/stores/productTabs'
import Reviews from '@/components/Products/ProductTabs/Reviews.vue'
import AppearTransition from '@shared/components/Transitions/AppearTransition.vue'

type Props = {
    description: string
    product: Product
}

const { description, product } = defineProps<Props>()
const store = useProductTabsStore(product)()
</script>

<template>
    <appear-transition>
        <div id="product-description">
            <tab-group :selected-index="store.currTab" @change="store.changeTab">
                <tab-list>
                    <div class="space-x-3">
                        <tab
                            v-for="tab in store.tabs"
                            :key="tab.title"
                            v-slot="{ selected }"
                            class="outline-none text-xl border-r border-border last:border-none pr-3"
                        >

                            <p
                                class="flex items-center text-[1rem] md:text-[1.2rem] border-b-2 pb-0.5 hover:text-primary transition-colors font-bold"
                                :class="{
                                    'border-primary text-primary': selected,
                                    'border-b-transparent text-gray-700': !selected,
                                }"
                            >
                                {{ trans(tab.title) }}

                                <div
                                    v-if="tab.badge && tab.badge > 0"
                                    class="bg-primary text-white w-5 h-5 flex items-center justify-center rounded-full font-normal text-xs ml-1.5"
                                >
                                    {{ tab.badge }}
                                </div>
                            </p>
                        </tab>
                    </div>
                </tab-list>

                <div class="mt-5 text-gray-500 text-md lg:text-lg min-h-[150px]">
                    <tab-panels>
                        <tab-panel>
                            <article v-html="description" class="prose lg:prose-lg max-w-none"></article>
                        </tab-panel>
                        <tab-panel>
                            <reviews :productSlug="product.slug" />
                        </tab-panel>
                    </tab-panels>
                </div>
            </tab-group>
        </div>
    </appear-transition>
</template>