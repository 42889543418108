import type { App } from 'vue'
import { i18nVue } from 'laravel-vue-i18n'

export default {
    install(app: App) {
        app.use(i18nVue, {
            lang: Locale,
            resolve: async lang => {
                const langs = import.meta.glob('../../../../lang/*.json')
                const call = await langs[`../../../../lang/${lang}.json`]
                return call()
            },
        })
    },
}
