<script setup lang="ts">
const emit = defineEmits<{
    (e: 'isSelected'): void
}>()

defineProps<{
    href: string
    meta: string | null
    label: string
    selected: boolean
}>()
</script>

<template>
    <li @mouseover="emit('isSelected')">
        <a
            :href
            :class="[
                'text-font px-4 py-2 block outline-primary gap-4 leading-5',
                'cursor-pointer flex justify-between items-center',
                selected ? 'bg-page-second outline' : '',
            ]"
        >
            {{ label }}

            <p v-if="meta" class="shrink-0">
                {{ meta }}
            </p>
        </a>
    </li>
</template>
