<script setup lang="ts">
import type { Product } from '@shared/types/models'
import { useImageSwitcherStore } from '@/stores/imageSwitcher'
import PlayIcon from '@shared/components/Icons/PlayIcon.vue'

const props = defineProps<{
    product: Product
}>()

const store = useImageSwitcherStore(props.product)()
</script>

<template>
    <div
        v-if="product.attachments!.length === 0"
        class="w-[48px] pl-1 flex-shrink-0"
    ></div>

    <div v-else class="space-y-4 pl-1 pt-1 hidden md:block flex-shrink-0">
        <button
            v-for="attachment in product.attachments"
            :key="attachment.id"
            class="block"
            type="button"
            @click="store.selectedImage = attachment"
        >
            <img
                v-if="attachment.type === 'image'"
                :src="`/storage/products/sm/${attachment.name}`"
                class="w-[48px] rounded-sm"
                :class="{
                    ring: store.selectedImage?.id === attachment.id,
                    'filter grayscale': product.status === 'hidden',
                }"
            />

            <button
                v-else
                type="button"
                class="w-[48px] flex flex-col items-center justify-center gap-1 h-12 rounded-sm"
                :class="{
                    ring: store.selectedImage?.id === attachment.id,
                }"
            >
                <play-icon class="w-7 h-7 rounded-sm" />

                <small class="font-heading text-[.6rem] tracking-wider">VIDEO</small>
            </button>
        </button>
    </div>
</template>
