<script setup lang="ts">
import { useLocaleStore } from '@/stores/locale'
import { useMenuStore } from '@/stores/menu'

type Props = {
    text?: string
}

defineProps<Props>()

const store = useLocaleStore()
const menuStore = useMenuStore()

function switchLocale() {
    store.showPopup = true
    menuStore.isOpen = false
}
</script>

<template>
    <button
        type="button"
        class="overflow-hidden flex items-center gap-3"
        :class="text ? '' : 'w-4 '"
        aria-label="Switch locale"
        @click="switchLocale"
    >
        <img
            :src="`/storage/icons/ua.webp`"
            width="53"
            height="53"
            class="w-4 h-4 rounded-full border border-border hover:border-primary"
        />

        <span v-if="text">{{ text }}</span>
    </button>
</template>
