<script setup lang="ts">
import { useCartStore } from '@/stores/cart'
import { trans } from 'laravel-vue-i18n'
import { onMounted } from 'vue'
import Popup from '@shared/components/Popup.vue'
import CartItem from '@/components/Cart/CartItem.vue'
import CartTotal from '@/components/Cart/CartTotal.vue'
import Message from '@shared/components/Message.vue'

const store = useCartStore()

onMounted(store.fetchCartItems)
</script>

<template>
    <popup
        @closed="store.isOpen = false"
        className="w-full max-w-[900px] !bg-gray-50"
        :label="trans('main.my_cart')"
    >
        <div class="grid grid-cols-1 sm:grid-cols-[3fr_2fr] gap-3">
            <message v-if="store.cartItems.length === 0" class="mt-5">
                {{ trans('main.cart_is_empty') }}
            </message>

            <ul v-else class="space-y-2">
                <cart-item v-for="item in store.cartItems" :key="item.slug" :item />
            </ul>

            <div>
                <div class="bg-white p-4">
                    <h2 class="text-lg font-black mb-3 uppercase font-heading">
                        {{ trans('main.total') }}
                    </h2>

                    <cart-total />
                </div>
            </div>
        </div>
    </popup>
</template>
