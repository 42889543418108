<template>
    <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
    >
        <defs>
            <path
                id="a"
                d="m9.996 1.763.203-.2A5.726 5.726 0 0 1 14.116 0c3.246-.004 5.88 2.725 5.884 6.097C20.01 13.845 10.014 18 10.014 18S.01 13.87 0 6.124C-.003 2.752 2.624.014 5.87.01A5.733 5.733 0 0 1 9.79 1.564l.205.199Z"
            />
        </defs>

        <g fill="none" fill-rule="evenodd">
            <mask id="b" fill="#fff"><use xlink:href="#a" /></mask>
            <use xlink:href="#a" fill="#FFF" fill-rule="nonzero" />
            <g mask="url(#b)">
                <path
                    fill="#F8E71C"
                    d="M9.5 13.65h19.259v2.33H9.5z"
                    style="transform: translateX(-4px)"
                />
                <path fill="#F8E71C" d="M9.5 13.65h19.259v2.33H9.5z" />

                <path
                    fill="#F8E71C"
                    d="M9.5 11.32h19.259v2.33H9.5z"
                    style="transform: translateX(-6px)"
                />
                <path fill="#F8E71C" d="M9.5 11.32h19.259v2.33H9.5z" />

                <path
                    fill="#F8E71C"
                    d="M9.5 8.99h19.259v2.33H9.5z"
                    style="transform: translateX(-9px)"
                />
                <path fill="#F8E71C" d="M9.5 8.99h19.259v2.33H9.5z" />

                <path
                    fill="#0770CF"
                    d="M9.5 6.66h19.259v2.33H9.5z"
                    style="transform: translateX(-9px)"
                />
                <path fill="#0770CF" d="M9.5 6.66h19.259v2.33H9.5z" />

                <path
                    fill="#0770CF"
                    d="M9.5 4.33h19.259v2.33H9.5z"
                    style="transform: translateX(-11px)"
                />
                <path fill="#0770CF" d="M9.5 4.33h19.259v2.33H9.5z" />

                <path
                    fill="#0770CF"
                    d="M9.5 2h19.636v2.333H9.5z"
                    style="transform: translateX(-9px)"
                />
                <path fill="#0770CF" d="M9.5 2h19.636v2.333H9.5z" />
            </g>
            <path
                fill="#FFF"
                fill-rule="nonzero"
                d="M10.618 15.474a21.327 21.327 0 0 0 3.137-2.076c2.697-2.166 4.249-4.619 4.245-7.299-.003-2.284-1.757-4.101-3.881-4.099-1.016 0-1.97.417-2.69 1.158l-1.43 1.467-1.432-1.463a3.748 3.748 0 0 0-2.695-1.151C3.749 2.013 1.998 3.837 2 6.12c.003 2.677 1.559 5.123 4.256 7.281a21.32 21.32 0 0 0 3.756 2.39c.191-.096.394-.202.606-.318ZM9.996 1.763l.203-.2A5.726 5.726 0 0 1 14.116 0c3.246-.004 5.88 2.725 5.884 6.097C20.01 13.845 10.014 18 10.014 18S.01 13.87 0 6.124C-.003 2.752 2.624.014 5.87.01A5.733 5.733 0 0 1 9.79 1.564l.205.199Z"
            />
        </g>
    </svg>
</template>
