<script setup lang="ts">
import { computed } from 'vue'

const props = defineProps<{
    href?: string
    disabled?: boolean
}>()

const className = computed(() => {
    let className = [
        'bg-primary text-white px-10 py-3 uppercase select-none',
        'font-heading font-bold transition-hover justify-center',
        'hover:bg-primary-hover flex items-center gap-3',
    ]

    if (props.disabled) {
        className.push('opacity-40 pointer-events-none')
    }

    return className
})
</script>

<template>
    <a v-if="href" :href :class="className">
        <slot />
    </a>

    <button v-else type="button" :class="className" :disabled>
        <slot />
    </button>
</template>
