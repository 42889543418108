import type { CartItem } from '@shared/types'

const KEY = 'cart'

export function getCartItems(): CartItem[] {
    return JSON.parse(localStorage.getItem(KEY) || '[]')
}

export function getCartItem(slug: string): CartItem | null {
    const cartItems = getCartItems()
    return cartItems.find(item => item.slug === slug) || null
}

export function addToCart(cartItem: CartItem): void {
    const cartItems = getCartItems()

    if (isInCart(cartItem.slug)) {
        incrementQuantity(cartItem.slug, cartItem.quantity)
        return
    }

    cartItems.push(cartItem)
    save(cartItems)
}

export function removeFromCart(slug: string): void {
    const cartItems = getCartItems().filter(item => item.slug !== slug)
    save(cartItems)
}

export function incrementQuantity(slug: string, amount: number): void {
    const cartItems = getCartItems().map(item => {
        if (item.slug === slug) {
            item.quantity += amount
        }

        return item
    })

    save(cartItems)
}

export function updateQuantity(slug: string, amount: number): void {
    const cartItems = getCartItems().map(item => {
        if (item.slug === slug) {
            item.quantity = amount
        }

        return item
    })

    save(cartItems)
}

export function clearCart(): void {
    localStorage.removeItem(KEY)
}

export function save(cartItems: CartItem[]): void {
    localStorage.setItem(KEY, JSON.stringify(cartItems))
}

function isInCart(slug: string): boolean {
    const cartItems = getCartItems()
    return cartItems.some(item => item.slug === slug)
}
