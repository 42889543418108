<script setup lang="ts">
import { trans } from 'laravel-vue-i18n'
import { useCheckoutStore } from '@/stores/checkout'
import InputField from '@shared/components/Form/InputField.vue'
import Block from '@/components/Checkout/Blocks/Block.vue'
import CheckoutSection from '@/components/Checkout/CheckoutSection.vue'
import phoneFormatter from 'phone-formatter'

const store = useCheckoutStore()

function formatPhoneNumber(): void {
    let phone = store.formData.phone

    if (!phone) {
        return
    }

    phone = phone.replace(/[+()-]/g, '')

    if (phone.startsWith('+3')) {
        phone = phone.slice(2)
    }

    store.formData.phone = phoneFormatter.format(phone, '+3(NNN)NNN-NN-NN')
}
</script>

<template>
    <checkout-section :label="trans('main.personal_details')">
        <block>
            <input-field
                v-model="store.formData.firstName"
                :label="trans('main.first_name')"
                :placeholder="trans('main.first_name')"
                id="first_name"
                required
            />

            <input-field
                v-model="store.formData.lastName"
                :label="trans('main.last_name')"
                :placeholder="trans('main.last_name')"
                id="last_name"
                required
            />

            <input-field
                v-model="store.formData.phone"
                :label="trans('main.phone_number')"
                @blur="formatPhoneNumber"
                :placeholder="trans('main.phone_number')"
                id="phone_number"
                required
            />

            <input-field
                v-model="store.formData.email"
                :label="trans('main.email')"
                :placeholder="trans('main.email')"
                id="email"
            />
        </block>
    </checkout-section>
</template>
