<script setup lang="ts">
import type { Product } from '@shared/types/models'
import { ref, onMounted } from 'vue'
import { trans } from 'laravel-vue-i18n'
import { getWishlist, removeFromWishlist } from '@/modules/storage/wishlistStorage'
import axios from 'axios'
import Popup from '@shared/components/Popup.vue'
import Spinner from '@shared/components/Spinner.vue'
import WishlistItem from '@/components/Wishlist/WishlistItem.vue'

type Emits = {
    (e: 'closed'): void
}

const products = ref<Product[]>([])
const loading = ref<boolean>(false)
const emit = defineEmits<Emits>()

onMounted(fetchProducts)

async function fetchProducts(): Promise<void> {
    const slugs = getWishlist()

    if (slugs.length === 0) {
        loading.value = false
        return
    }

    loading.value = true

    const uri = '/api/products/by-slugs'
    const config = { params: { slugs } }

    const items = await axios.get<Product[]>(uri, config)
    products.value = items.data

    loading.value = false
}

function removeProduct(slug: string): void {
    products.value = products.value.filter(p => p.slug !== slug)
    removeFromWishlist(slug)
}
</script>

<template>
    <popup
        @closed="emit('closed')"
        className="w-full max-w-[700px] min-h-72 !bg-gray-50"
        :label="trans('product.wishlist')"
    >
        <spinner v-if="loading" />

        <div v-else-if="products.length === 0">
            <p class="text-center text-font-second text-lg mt-10">
                {{ trans('product.no_products_yet') }}
            </p>
        </div>

        <div v-else class="overflow-y-auto space-y-2">
            <wishlist-item
                v-for="product in products"
                :key="product.slug"
                :product
                @remove="removeProduct(product.slug)"
            />
        </div>
    </popup>
</template>
