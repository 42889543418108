<script setup lang="ts">
import type { Lang } from '@shared/types'
import { ref } from 'vue'
import { trans } from 'laravel-vue-i18n'
import Popup from '@shared/components/Popup.vue'
import SelectField from '@shared/components/Form/SelectField.vue'
import BigButton from '@shared/components/Buttons/BigButton.vue'
import { useLocaleStore } from '@/stores/locale'

const store = useLocaleStore()
const currentSlug = ref<Lang>(store.currentLocale.slug as Lang)

function switchLocale(): void {
    store.switchLocale(currentSlug.value)
}
</script>

<template>
    <popup
        v-if="store.showPopup"
        @closed="store.showPopup = false"
        :label="trans('main.language')"
    >
        <form @submit.prevent="switchLocale" class="w-72">
            <select-field
                :label="trans('main.select_preferred_language')"
                id="language"
                v-model="currentSlug"
            >
                <option
                    v-for="l in $localeLinks"
                    :key="l.slug"
                    :value="l.slug"
                    :selected="l.slug === store.currentLocale.slug"
                >
                    {{ l.title }}
                </option>
            </select-field>

            <big-button
                type="submit"
                class="w-full mt-4 !bg-gray-800 hover:!bg-gray-900"
            >
                {{ trans('main.save') }}
            </big-button>
        </form>
    </popup>
</template>
