import type { Pagination } from '@shared/types/response'
import type { Review } from '@shared/types/models'
import { onMounted, ref } from 'vue'
import axios from 'axios'
import handleError from '@/modules/handleError'

export function useReviews(productSlug: string) {
    const reviews = ref<Review[]>([])
    const loading = ref<boolean>(false)
    const nextPageUrl = ref<string | null>(null)
    const url = ref<string>('/api/reviews')

    onMounted(() => fetchReviews(true))

    async function fetchReviews(refresh: boolean): Promise<void> {
        if (loading.value) {
            return
        }

        if (refresh) {
            loading.value = true
        }

        const params = {
            slug: productSlug,
        }

        try {
            const resp = await axios.get<Pagination<Review[]>>(url.value, { params })

            nextPageUrl.value = resp.data.next_page_url

            reviews.value = refresh
                ? resp.data.data
                : [...reviews.value, ...resp.data.data]
        } catch (e) {
            handleError(e)
        }

        loading.value = false
    }

    function loadMore(): void {
        if (!nextPageUrl.value) {
            return
        }

        url.value = nextPageUrl.value
        fetchReviews(false)
    }

    return {
        reviews,
        loading,
        nextPageUrl,
        loadMore,
    }
}
