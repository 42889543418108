<script setup lang="ts">
import type { StyleValue } from 'vue'
import { computed } from 'vue'

const props = defineProps<{
    label: string
    imageUri?: string | null
    href?: string | null
}>()

const className =
    'w-full text-left p-4 text-lg font-black uppercase flex items-center'

const imageStyles = computed<StyleValue>(() => {
    return props.imageUri
        ? {
              backgroundImage: `url(${props.imageUri})`,
              backgroundSize: '288px 100%',
          }
        : {}
})
</script>

<template>
    <li class="bg-gray-100 m-4 h-[96px] flex items-center" :style="imageStyles">
        <a v-if="href" :href :class="className">
            {{ label }}
        </a>

        <button v-else type="button" :class="className">
            {{ label }}
        </button>
    </li>
</template>
