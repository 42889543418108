import type { Product, Attachment } from '@shared/types/models'
import { ref } from 'vue'
import { defineStore } from 'pinia'

export const useImageSwitcherStore = (product: Product) => {
    return defineStore('imageSwitcher', () => {
        const selectedImage = ref<Attachment | null>(
            product.attachments ? product.attachments[0] : null,
        )

        function nextImage(): void {
            if (!product.attachments || !selectedImage.value) {
                return
            }

            const attachments = product.attachments.filter(a => a.type === 'image')

            const currentIndex = attachments.findIndex(
                a => a.id === selectedImage.value?.id,
            )

            if (currentIndex === -1) {
                return
            }

            const nextIndex = (currentIndex + 1) % attachments.length

            selectedImage.value = attachments[nextIndex]
        }

        function prevImage(): void {
            if (!product.attachments || !selectedImage.value) {
                return
            }

            const attachments = product.attachments.filter(a => a.type === 'image')

            const currentIndex = attachments.findIndex(
                a => a.id === selectedImage.value?.id,
            )

            if (currentIndex === -1) {
                return
            }

            const nextIndex =
                (currentIndex - 1 + attachments.length) % attachments.length

            selectedImage.value = attachments[nextIndex]
        }

        return {
            selectedImage,
            nextImage,
            prevImage,
        }
    })
}
