<script setup lang="ts">
import { ref, watch } from 'vue'
import { trans } from 'laravel-vue-i18n'
import { useSearchStore } from '@/stores/search'
import SearchIcon from '@shared/components/Icons/SearchIcon.vue'
import SearchDropdown from '@/components/Navbar/Search/SearchDropdown.vue'

defineProps<{
    withOverlay: boolean
}>()

const store = useSearchStore()
const inputRef = ref<HTMLInputElement | null>(null)

watch(inputRef, newValue => {
    if (newValue) {
        store.input = newValue
    }
})
</script>

<template>
    <form class="relative flex-1 text-center w-full max-w-[420px]">
        <input
            v-model="store.query"
            @input="store.search"
            @blur="store.query = ''"
            ref="inputRef"
            autocomplete="off"
            type="text"
            :class="[
                'w-full h-10 px-5 rounded-full bg-page text-font focus:outline-gray-800',
                'border border-border relative z-50',
            ]"
            :placeholder="trans('product.search_placeholder') + '...'"
        />

        <button
            type="button"
            class="h-10 w-10 text-font text-lg rounded-full absolute right-0 top-0 transition-colors hover:bg-black/20"
        >
            <search-icon class="w-5 h-5 mx-auto" />
        </button>

        <search-dropdown v-if="store.results.length > 0" :withOverlay />
    </form>
</template>
