<script setup lang="ts">
import { onMounted, computed } from 'vue'
import { useCartStore } from '@/stores/cart'
import { useCheckoutStore } from '@/stores/checkout'
import { trans } from 'laravel-vue-i18n'
import CheckoutTotal from '@/components/Checkout/CheckoutTotal.vue'
import PersonalBlock from '@/components/Checkout/Blocks/PersonalBlock.vue'
import DeliveryBlock from '@/components/Checkout/Blocks/DeliveryBlock.vue'
import DeliveryAddressBlock from '@/components/Checkout/Blocks/DeliveryAddressBlock.vue'
import PaymentBlock from '@/components/Checkout/Blocks/PaymentBlock.vue'
import CommentBlock from '@/components/Checkout/Blocks/CommentBlock.vue'
import BigButton from '@shared/components/Buttons/BigButton.vue'
import CheckIcon from '@shared/components/Icons/CheckIcon.vue'

const cartStore = useCartStore()
const store = useCheckoutStore()

onMounted(cartStore.fetchCartItems)

const disabled = computed<boolean>(() => {
    for (const item of cartStore.cartItems) {
        if (item.quantity < item.min_order_quantity) {
            return true
        }
    }

    return store.creating
})
</script>

<template>
    <h2
        v-if="cartStore.quantity === 0"
        class="text-xl md:text-3xl mx-auto text-center mt-8"
    >
        {{ trans('product.no_products') }}
    </h2>

    <div
        v-else
        :class="[
            'grid grid-cols-1 lg:grid-cols-[3fr_2fr] gap-4',
            'max-w-product-page mx-auto mt-4',
        ]"
    >
        <div class="grid gap-4">
            <personal-block />
            <delivery-block />
            <delivery-address-block />
            <payment-block />
            <comment-block />

            <div class="flex justify-end">
                <big-button @click="store.createOrder" :disabled>
                    <check-icon class="w-5 h-5" />
                    {{ trans('main.create_order') }}
                </big-button>
            </div>
        </div>

        <checkout-total />
    </div>
</template>
