<script setup lang="ts">
import type { Product, Attachment } from '@shared/types/models'
import { computed } from 'vue'
import { trans } from 'laravel-vue-i18n'
import linkTo from '@shared/modules/linkTo'
import EditProductButton from '@/components/Products/EditProductButton.vue'
import LikeButton from '@/components/Products/LikeButton.vue'

type Props = {
    product: Product
    categorySlug: string
    useShortTitle?: boolean
}

const props = withDefaults(defineProps<Props>(), {
    useShortTitle: false,
})

const defaultImage = '/storage/products/sm/default.webp'

const images = computed<Attachment[]>(() => {
    if (!props.product.attachments) {
        return []
    }

    return props.product.attachments.filter(a => a.type === 'image')
})

const image1 = computed<string>(() => {
    const attachment = images.value[0] ?? null
    return attachment ? `/storage/products/sm/${attachment.name}` : defaultImage
})

const image2 = computed<string | null>(() => {
    const attachment = images.value[1] ?? null
    return attachment ? `/storage/products/lg/${attachment.name}` : null
})

const label = computed<string>(() => {
    let title = props.product.name[Locale].trim()

    if (!props.useShortTitle) {
        return title
    }

    const words = title.split(' ')

    return words.slice(0, 2).join(' ')
})
</script>

<template>
    <a
        :href="linkTo(`${categorySlug}/${product.slug}`)"
        class="relative"
        :class="{ 'opacity-70': product.status === 'hidden' }"
    >
        <edit-product-button v-if="$auth" :slug="product.slug" />

        <div class="relative">
            <like-button :product class="absolute bottom-2 right-2 z-10 bg-white" />

            <img
                :src="image1"
                :alt="`${product.name[$locale]} first image`"
                class="w-full"
                width="384"
                height="448"
                :class="product.status === 'hidden' ? 'filter grayscale' : ''"
            />

            <img
                v-if="image2"
                :src="image2"
                :alt="`${product.name[$locale]} second image`"
                :class="[
                    'absolute top-0 left-0 w-full !duration-100 !opacity-0',
                    'transition-opacity hover:!opacity-100',
                    product.status === 'hidden' ? 'filter grayscale' : '',
                ]"
                width="384"
                height="448"
            />
        </div>

        <div class="px-1">
            <h2 class="mt-2 leading-5" v-html="label"></h2>

            <h3
                v-if="product.status === 'hidden'"
                class="font-bold font-heading text-sm text-red-700"
            >
                {{ trans('product.not_in_stock') }}
            </h3>

            <b class="font-heading text-sm block mt-1">{{ product.price }}.00 ₴</b>
        </div>
    </a>
</template>
