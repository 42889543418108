import type { Product } from '@shared/types/models'
import { defineStore } from 'pinia'
import { ref, onMounted } from 'vue'
import listenEvent from '@shared/modules/listenEvent'

export const useProductTabsStore = (product: Product) => {
    return defineStore('productTabs', () => {
        const tabs = ref([
            {
                title: 'product.about_the_product',
                badge: null,
                hash: '#about',
            },
            {
                title: 'product.reviews',
                badge: product.reviews_count,
                hash: '#reviews',
            },
        ])

        const currTab = ref<number>(0)

        onMounted(() => {
            setTabOnLoad()

            listenEvent('scrolledToReviews', () => {
                changeTab(tabs.value.findIndex(tab => tab.hash === '#reviews'))
                scrollToTab()
            })
        })

        function setTabOnLoad() {
            const currHash = window.location.hash
            const matchTab = tabs.value.find(tab => tab.hash === currHash)

            if (!matchTab) return

            currTab.value = tabs.value.indexOf(matchTab)

            scrollToTab()
        }

        function scrollToTab() {
            const scrollTo = document.getElementById('product-description')

            if (!scrollTo) {
                console.error('Element #product-description not found')
                return
            }

            scrollTo.scrollIntoView({ behavior: 'smooth', block: 'start' })
        }

        function changeTab(i: number) {
            const tab = tabs.value[i]

            if (!tab) return

            window.location.hash = tab.hash
            currTab.value = i
        }

        return {
            tabs,
            currTab,
            changeTab,
        }
    })
}
