<script setup lang="ts">
import PencilIcon from '@shared/components/Icons/PencilIcon.vue'

const props = defineProps<{
    slug: string
}>()

function redirect(): void {
    window.location.href = `/admin/products/${props.slug}/edit`
}
</script>

<template>
    <button
        @click.prevent="redirect"
        title="Редактировать товар"
        type="button"
        :class="[
            'absolute right-3 top-3 bg-white/80 w-9 h-9 flex items-center',
            'rounded-full border border-white justify-center z-10',
            'hover:scale-125 transition-transform',
        ]"
    >
        <pencil-icon class="w-5 h-5" />
    </button>
</template>
