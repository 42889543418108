<script setup lang="ts">
import type { Product } from '@shared/types/models'
import { useLikeButton } from '@/composables/useLikeButton'
import HeartIcon from '@shared/components/Icons/HeartIcon.vue'

const props = defineProps<{
    product: Product
}>()

const { like, liked } = useLikeButton(props.product.slug)
</script>

<template>
    <button
        @click.prevent="like"
        type="button"
        class="bg-white/80 p-1.5 rounded-full group"
    >
        <heart-icon
            class="w-6 h-6 group-hover:fill-black"
            :class="{ 'fill-black': liked }"
        />
    </button>
</template>
