<template>
    <transition
        enter-from-class="transform translate-x-96 opacity-0"
        leave-from-class="transform translate-x-0 opacity-100"
        leave-to-class="transform translate-x-96 opacity-0"
        enter-to-class="transform translate-x-0 "
        enter-active-class="transition duration-500"
        leave-active-class="transition duration-500"
    >
        <slot />
    </transition>
</template>
