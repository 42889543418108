<script setup lang="ts">
import type { CartItem } from '@shared/types'
import { useCartStore } from '@/stores/cart'
import { trans } from 'laravel-vue-i18n'
import linkTo from '@shared/modules/linkTo'
import SquareButton from '@shared/components/Buttons/SquareButton.vue'
import CloseIcon from '@shared/components/Icons/CloseIcon.vue'
import SaveForLaterButton from '@/components/Cart/SaveForLaterButton.vue'
import Quantity from '@/components/Cart/Quantity.vue'
import MinQuantity from '@/components/MinQuantity.vue'

type Props = {
    item: CartItem
    editable?: boolean
}

const props = withDefaults(defineProps<Props>(), {
    editable: true,
})

const store = useCartStore()
</script>

<template>
    <li class="bg-white p-4" :class="{ 'opacity-80': item.notAvailable }">
        <div class="flex gap-4 justify-between">
            <div class="flex items-center gap-5">
                <a :href="linkTo(item.uri)" class="shrink-0">
                    <img
                        :src="`/storage/products/sm/${item.imageName}`"
                        :alt="`Photo of ${item.imageName}`"
                        width="384"
                        height="448"
                        class="w-20 rounded-sm"
                    />
                </a>

                <div>
                    <div>
                        <div
                            v-if="store.loading"
                            class="w-10 h-5 bg-page-second animate-pulse rounded-md"
                        ></div>

                        <span v-else-if="!item.price"></span>

                        <span
                            v-else
                            class="text-md text-neutral-700 font-bold font-heading"
                        >
                            {{ item.price * item.quantity }}.00 ₴
                        </span>
                    </div>

                    <a :href="linkTo(item.uri)">
                        <h2 class="leading-5">{{ item.name[$locale] }}</h2>
                    </a>

                    <save-for-later-button v-if="editable" :cartItem="item" />

                    <quantity :item :editable />

                    <div class="flex justify-between items-center">
                        <h3 v-if="item.notAvailable" class="text-red-700">
                            {{ trans('product.not_in_stock') }}
                        </h3>
                    </div>
                </div>
            </div>

            <square-button
                v-if="editable"
                @click="store.removeItem(props.item.slug)"
                :icon="CloseIcon"
                iconClassName="text-gray-600"
                class="border-0"
            />
        </div>

        <min-quantity
            v-if="item.quantity < item.min_order_quantity"
            :min="item.min_order_quantity"
            :slug="item.slug"
            :withButton="true"
        />
    </li>
</template>
