<script setup lang="ts">
import { trans } from 'laravel-vue-i18n'
import { useCartStore } from '@/stores/cart'

type Props = {
    min: number
    slug?: string
    withButton?: boolean
}

const props = withDefaults(defineProps<Props>(), {
    withButton: false,
})

const store = useCartStore()

function updateQuantity(): void {
    if (!props.slug) {
        console.warn('MinQuantity: slug is not provided')
        return
    }

    store.updateItemQuantity(props.slug, props.min)
}
</script>

<template>
    <p
        class="mt-4 bg-orange-50 py-2 px-4 leading-5 flex gap-2 items-center justify-between"
    >
        {{
            trans('product.min_order_quantity', {
                min: min.toString(),
            })
        }}

        <button
            v-if="withButton"
            @click="updateQuantity"
            type="button"
            class="bg-page border border-border py-2 px-3 rounded-md hover:border-primary hover:text-primary"
        >
            {{ trans('main.add') }}
        </button>
    </p>
</template>
