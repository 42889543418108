import { createApp } from 'vue/dist/vue.esm-bundler'
import { createPinia } from 'pinia'
import 'vue-inner-image-zoom/lib/vue-inner-image-zoom.css'
import 'tippy.js/dist/tippy.css'
import axios from 'axios'
import i18n from '@/plugins/i18n'
import globalValues from '@/plugins/globalValues'
import VueTippy from 'vue-tippy'
import Navbar from '@/components/Navbar/Navbar.vue'
import InputField from '@shared/components/Form/InputField.vue'
import Checkbox from '@shared/components/Form/Checkbox.vue'
import FormButton from '@shared/components/Form/FormButton.vue'
import Products from '@/components/Products/Products.vue'
import ImageSwitcher from '@/components/Products/ImageSwitcher/ImageSwitcher.vue'
import ReadAllDescriptionButton from '@/components/Products/ReadAllDescriptionButton.vue'
import ProductControls from '@/components/Products/ProductControls.vue'
import Recommended from '@/components/Products/Recommended.vue'
import LazyLoading from '@shared/components/LazyLoading.vue'
import AppearTransition from '@shared/components/Transitions/AppearTransition.vue'
import SwitchLocalePopup from '@/components/Popups/SwitchLocalePopup.vue'
import ViewVideoButton from '@/components/Products/ViewVideoButton/ViewVideoButton.vue'
import Social from '@/components/MobileMenu/Social.vue'
import ContactUsForm from '@/components/ContactUsForm.vue'
import Checkout from '@/components/Checkout/Checkout.vue'
import OrderStatus from '@/components/OrderStatus/OrderStatus.vue'
import HomeVideo from '@/components/HomeVideo.vue'
import ProductTabs from '@/components/Products/ProductTabs/ProductTabs.vue'

const app = createApp({
    components: {
        Navbar,
        InputField,
        Checkbox,
        FormButton,
        Products,
        ImageSwitcher,
        ReadAllDescriptionButton,
        ProductControls,
        Recommended,
        LazyLoading,
        AppearTransition,
        SwitchLocalePopup,
        ViewVideoButton,
        Social,
        ContactUsForm,
        Checkout,
        OrderStatus,
        HomeVideo,
        ProductTabs,
    },
})

app.use(createPinia()).use(i18n).use(VueTippy).use(globalValues).mount('#main')

// add Locale to all axios requests
axios.interceptors.request.use(config => {
    config.headers['accept-language'] = Locale
    return config
})
