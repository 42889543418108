<script setup lang="ts">
import type { CartItem } from '@shared/types'
import { ref, watchEffect } from 'vue'
import { useCartStore } from '@/stores/cart'
import { trans } from 'laravel-vue-i18n'
import PlusIcon from '@shared/components/Icons/PlusIcon.vue'
import MinusIcon from '@shared/components/Icons/MinusIcon.vue'

const MAX_QUANTITY = 999

const props = defineProps<{
    item: CartItem
    editable?: boolean
}>()

const store = useCartStore()
const quantity = ref<number>(props.item.quantity)

watchEffect(() => {
    quantity.value = props.item.quantity
})

function updateQuantity(): void {
    if (quantity.value > MAX_QUANTITY) {
        quantity.value = MAX_QUANTITY
    }

    store.updateItemQuantity(props.item.slug, quantity.value)
}

function incrementQuantity(): void {
    if (quantity.value < MAX_QUANTITY) {
        quantity.value++
    }

    updateQuantity()
}

function decrementQuantity(): void {
    if (quantity.value > 1) {
        quantity.value--
    }

    updateQuantity()
}
</script>

<template>
    <label class="flex items-center mt-1" :class="editable ? 'gap-2' : 'gap-1'">
        <button
            v-if="editable"
            type="button"
            class="border border-border p-1.5 rounded-md"
            :class="{
                'bg-page-second border-transparent text-gray-400': quantity <= 1,
            }"
            :disabled="quantity <= 1"
            @click="decrementQuantity"
        >
            <minus-icon class="w-4 h-4" />
        </button>

        <span v-else>{{ trans('product.quantity') }}:</span>

        <input
            v-if="editable"
            type="number"
            :max="MAX_QUANTITY"
            @blur="updateQuantity"
            v-model="quantity"
            class="border border-border w-14 rounded-md text-md text-center py-0.5"
            :disabled="store.loading"
        />

        <span v-else>{{ item.quantity }}</span>

        <button
            v-if="editable"
            type="button"
            class="border border-border p-1.5 rounded-md"
            :disabled="quantity >= MAX_QUANTITY"
            @click="incrementQuantity"
        >
            <plus-icon class="w-4 h-4" />
        </button>
    </label>
</template>
