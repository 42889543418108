<script setup lang="ts">
import { onMounted } from 'vue'
import { useSearchStore } from '@/stores/search'
import SearchItem from '@/components/Navbar/Search/SearchItem.vue'
import Overlay from '@shared/components/Overlay.vue'

defineProps<{
    withOverlay: boolean
}>()

const store = useSearchStore()

onMounted(() => {
    window.addEventListener('keydown', store.selectSearchResult)
})
</script>

<template>
    <overlay v-if="withOverlay" @closed="store.results = []" />

    <ul
        :class="[
            'absolute bg-white inset-x-0 top-3 pt-10 pb-5 rounded-xl',
            'z-40 text-left rounded-b-md shadow-md max-h-[500px]',
            'divide-y divide-border-light overflow-y-scroll',
        ]"
    >
        <search-item
            v-for="result in store.results"
            :key="result.uri"
            :href="result.uri"
            :meta="result.meta"
            :label="result.title"
            :selected="store.selected === result.uri"
            @isSelected="store.selected = result.uri"
        />
    </ul>
</template>
