import { ref } from 'vue'
import { defineStore } from 'pinia'
import axios from 'axios'
import handleError from '@/modules/handleError'
import { debounce } from 'lodash'
import { SearchResult } from '@shared/types'

export const useSearchStore = defineStore('search', () => {
    const isOpen = ref(false)
    const query = ref<string>('')
    const results = ref<SearchResult[]>([])
    const input = ref<HTMLInputElement | null>(null)
    const selected = ref<string | null>(null)

    const search = debounce(searchItems, 300)

    async function searchItems(): Promise<void> {
        if (!query.value) {
            results.value = []
            return
        }

        const uri = `/api/search?q=${query.value}&locale=${Locale}`

        try {
            const resp = await axios.get<SearchResult[]>(uri)
            results.value = resp.data
        } catch (error) {
            handleError(error)
        }
    }

    function selectSearchResult(e: KeyboardEvent): void {
        const firstUri = results.value[0]?.uri

        if (selected.value === null) {
            selected.value = firstUri
            return
        }

        if (e.key === 'ArrowDown') {
            const index = results.value.findIndex(
                result => result.uri === selected.value,
            )

            selected.value = results.value[index + 1]?.uri || firstUri
            return
        }

        if (e.key === 'ArrowUp') {
            const index = results.value.findIndex(
                result => result.uri === selected.value,
            )

            selected.value =
                results.value[index - 1]?.uri ||
                results.value[results.value.length - 1]?.uri
            return
        }

        if (e.key === 'Enter') {
            e.preventDefault()
            window.location.href = selected.value
        }
    }

    return {
        query,
        input,
        isOpen,
        results,
        search,
        selected,
        selectSearchResult,
    }
})
