<script setup lang="ts">
import type { Component } from 'vue'

defineProps<{
    icon?: Component
    href?: string
    iconClassName?: string
}>()

const className =
    'hover:bg-nav-second px-3 h-full transition-colors text-xl text-white'
</script>

<template>
    <a v-if="href" :href="href" :class="className">
        <component v-if="icon" :is="icon" class="w-6 h-6" :class="iconClassName" />
        <slot />
    </a>

    <button v-else type="button" :class="className">
        <component v-if="icon" :is="icon" class="w-6 h-6" :class="iconClassName" />
        <slot />
    </button>
</template>
