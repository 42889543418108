import type { LocaleLink } from '@shared/types'
import Cookies from 'js-cookie'
import { ref, computed } from 'vue'
import { defineStore } from 'pinia'
import trim from '@shared/modules/trim'

export const useLocaleStore = defineStore('locale', () => {
    const showPopup = ref<boolean>(false)

    const currentLocale = computed<LocaleLink>(() => {
        const res = LocaleLinks.find(link => link.slug === Locale)
        return res || LocaleLinks[0]
    })

    function changeLocaleInUrl(locale: string): string | null {
        let path = location.pathname
        let localeFromURL = path.substring(0, 4)

        localeFromURL = trim(localeFromURL, '/')
        path = trim(path, '/')

        const locales = LocaleLinks.map(link => link.slug)

        if (localeFromURL === locale) {
            return null
        }

        if (locales.includes(localeFromURL)) {
            const replacement = locale === DefaultLocale ? '/' : `/${locale}/`
            const newPath = trim(path.replace(`${localeFromURL}`, replacement), '/')

            return newPath === '' || newPath == '/' ? '/' : `/${newPath}`
        }

        return locale === DefaultLocale ? `/${path}` : `/${locale}/${path}`
    }

    function switchLocale(locale: string): void {
        Cookies.set('locale', locale, { expires: 365, secure: false })

        const newHref = changeLocaleInUrl(locale)

        if (newHref) {
            location.href = newHref + location.search
        }
    }

    return {
        showPopup,
        currentLocale,
        switchLocale,
    }
})
