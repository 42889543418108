<script setup lang="ts">
import type { Option } from '@shared/types/models'
import { computed } from 'vue'
import { useOptionStore } from '@/stores/option'
import isMobileScreen from '@shared/modules/isMobileScreen'

const store = useOptionStore()

const videoLg = computed<Option | null>(() => {
    return store.options.find(o => o.name === 'home-banner-video-lg') || null
})

const videoSm = computed<Option | null>(() => {
    return store.options.find(o => o.name === 'home-banner-video-sm') || null
})

const posterLg = computed<Option | null>(() => {
    return store.options.find(o => o.name === 'home-banner-poster-lg') || null
})

const posterSm = computed<Option | null>(() => {
    return store.options.find(o => o.name === 'home-banner-poster-sm') || null
})

const redirectUrl = computed<string>(() => {
    const option = store.options.find(o => o.name === 'home-banner-redirect-url')
    return option && option.value ? option.value : 'javascript:'
})

const videoSrc = computed<string | null>(() => {
    if (isMobileScreen() && videoSm.value) {
        return `/storage/options/${videoSm.value.value}`
    } else if (videoLg.value) {
        return `/storage/options/${videoLg.value.value}`
    }

    return null
})

const posterSrc = computed<string>(() => {
    if (isMobileScreen() && posterSm.value) {
        return `/storage/options/${posterSm.value.value}`
    } else if (posterLg.value) {
        return `/storage/options/${posterLg.value.value}`
    }

    return ''
})
</script>

<template>
    <div class="text-center">
        <a v-if="videoSm && videoLg && videoSrc" :href="redirectUrl">
            <section class="mx-auto">
                <video
                    :src="videoSrc"
                    :poster="posterSrc"
                    playsinline
                    controls
                    loop
                    muted
                    width="1600"
                ></video>
            </section>
        </a>

        <a v-else-if="posterSm && posterLg" :href="redirectUrl">
            <section class="mx-auto">
                <img
                    :src="`/storage/options/${isMobileScreen() ? posterSm.value : posterLg.value}`"
                    playsinline
                    controls
                    loop
                    muted
                    width="1600"
                />
            </section>
        </a>
    </div>
</template>
