<script setup lang="ts">
import type { Category } from '@shared/types/models'
import { trans } from 'laravel-vue-i18n'
import { computed } from 'vue'
import { useCategoryStore } from '@/stores/category'
import { useMenuStore } from '@/stores/menu'
import { useOptionStore } from '@/stores/option'
import { useLocaleStore } from '@/stores/locale'
import MobileMenuLink from '@/components/MobileMenu/MobileMenuLink.vue'
import NestedMobileMenu from '@/components/MobileMenu/NestedMobileMenu.vue'
import SlideFromRightTransition from '@shared/components/Transitions/SlideFromRightTransition.vue'
import CloseMenuButton from '@/components/MobileMenu/CloseMenuButton.vue'
import LocaleSwitcher from '@/components/LocaleSwitcher.vue'
import MobileNavMenu from '@/components/MobileMenu/MobileNavMenu.vue'
import Social from '@/components/MobileMenu/Social.vue'
import isTabletScreen from '@shared/modules/isTabletScreen'

const store = useCategoryStore()
const localeStore = useLocaleStore()
const menuStore = useMenuStore()
const optionStore = useOptionStore()

const homeButtonImageURI = computed<string | null>(() => {
    const option = optionStore.options.find(
        option => option.name === 'home-button-image',
    )

    return option ? `/storage/options/${option.value}` : null
})

const showMenu = computed(() => {
    return (
        store.selectedCategory &&
        store.selectedCategory.categories!.length > 0 &&
        isTabletScreen()
    )
})

function noCategories(category: Category): boolean {
    if (!category.categories) {
        return true
    }

    return category.categories.length === 0
}

function openSubCategories(category: Category): void {
    if (!category.categories || category.categories.length === 0) {
        return
    }

    store.selectedCategory = category
}

function closeMenu(): void {
    store.selectedCategory = null
    menuStore.isOpen = false
}
</script>

<template>
    <aside
        :class="[
            'fixed inset-y-0 left-0 bg-page z-50 shadow-lg',
            'xl:hidden w-sidebar-menu overflow-x-hidden',
            store.selectedCategory ? 'overflow-y-clip' : 'overflow-y-auto',
        ]"
    >
        <ul class="space-y-3">
            <mobile-menu-link
                :label="trans('main.main')"
                href="/"
                class="!h-12"
                :imageUri="homeButtonImageURI"
            />

            <mobile-menu-link
                v-for="category in store.categories"
                :key="category.id"
                :label="category.name[$locale]"
                :href="noCategories(category) ? `/${category.slug}` : null"
                :imageUri="`/storage/categories/wide/${category.navbar_image_name}`"
                @click="openSubCategories(category)"
            />
        </ul>

        <close-menu-button @click="closeMenu" />

        <slide-from-right-transition>
            <nested-mobile-menu
                v-if="showMenu"
                :category="store.selectedCategory!"
                :categories="store.selectedCategory!.categories!"
                @closeMenu="store.selectedCategory = null"
            />
        </slide-from-right-transition>

        <social />
        <mobile-nav-menu />

        <div class="bg-page-second p-4 flex items-center justify-center gap-3">
            {{ trans('main.your_language_is') }}:

            <locale-switcher :text="localeStore.currentLocale.title" />
        </div>
    </aside>
</template>
