<script setup lang="ts">
import { useCartStore } from '@/stores/cart'
import NavButton from '@/components/Navbar/NavButton.vue'
import CartPopup from '@/components/Cart/CartPopup.vue'

const store = useCartStore()
</script>

<template>
    <div
        @click="store.isOpen = true"
        class="flex items-center justify-center h-full relative"
    >
        <nav-button>
            <div class="button bg-center bg-no-repeat w-[22px] h-[22px]"></div>
            <span
                v-if="store.quantity > 0"
                class="font-heading text-black absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 text-[.7rem] font-bold mt-[3px] -tracking-tight"
            >
                {{ store.quantity }}
            </span>
        </nav-button>
    </div>

    <teleport to="body">
        <cart-popup v-if="store.isOpen" />
    </teleport>
</template>

<style scoped>
.button {
    background: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3E%3Cpath fill='%23FFF' d='M4.083 5a6.002 6.002 0 0 1 11.834 0H20v14.986L0 20V5h4.083zM14 5c0-1.001-1.06-3.274-4-3.274S6 4.006 6 5c0 0 8 .012 8 0z'/%3E%3C/svg%3E");
}
</style>
