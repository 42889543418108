import { ref } from 'vue'
import { trans } from 'laravel-vue-i18n'
import {
    addToWishlist,
    isWishlisted,
    removeFromWishlist,
} from '@/modules/storage/wishlistStorage'
import showToast from '@shared/modules/showToast'
import axios from 'axios'

export function useLikeButton(slug: string) {
    const liked = ref<boolean>(isWishlisted(slug))
    const savedLike = ref<boolean>(false)

    async function like(): Promise<void> {
        if (liked.value) {
            removeFromWishlist(slug)
            showToast({ text: trans('product.product_removed_from_wishlist') })
            liked.value = false
            return
        }

        addToWishlist(slug)
        showToast({ text: trans('product.product_added_to_wishlist') })

        if (!savedLike.value) {
            await addLikeToProduct()
            savedLike.value = true
        }

        liked.value = true
    }

    async function addLikeToProduct(): Promise<void> {
        try {
            await axios.post(`/api/products/${slug}/like`)
        } catch (e) {
            console.error(e)
        }
    }

    return {
        liked,
        like,
    }
}
