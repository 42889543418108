<script setup lang="ts">
import { trans } from 'laravel-vue-i18n'
import { useCheckoutStore } from '@/stores/checkout'
import DropdownSelectField from '@shared/components/Form/DropdownSelectField.vue'
import InputField from '@shared/components/Form/InputField.vue'
import Block from '@/components/Checkout/Blocks/Block.vue'
import CheckoutSection from '@/components/Checkout/CheckoutSection.vue'

const store = useCheckoutStore()

function oblastSelected(): void {
    store.fetchCities()
    store.formData.address.city = null
    store.formData.address.warehouse = null
}

function citySelected(): void {
    store.fetchWarehouses()
    store.formData.address.warehouse = null
}
</script>

<template>
    <checkout-section :label="trans('main.delivery_address')">
        <block>
            <dropdown-select-field
                :label="trans('main.oblast')"
                @change="oblastSelected"
                v-model="store.formData.address.area"
                id="oblast"
                :placeholder="trans('main.choose')"
                :items="store.areas"
                itemKey="ref"
                itemLabel="description"
            />

            <dropdown-select-field
                :label="trans('main.city')"
                @change="citySelected"
                v-model="store.formData.address.city"
                :disabled="!store.formData.address.area"
                id="city"
                :placeholder="trans('main.choose')"
                :items="store.cities"
                itemKey="ref"
                itemLabel="description"
            />

            <dropdown-select-field
                v-if="store.formData.delivery === 'warehouse'"
                :label="trans('main.new_mail_department')"
                v-model="store.formData.address.warehouse"
                id="warehouse"
                :disabled="!store.formData.address.city"
                :placeholder="trans('main.choose')"
                :items="store.warehouses"
                itemKey="ref"
                itemLabel="description"
            />

            <input-field
                v-if="store.formData.delivery === 'address'"
                :label="trans('main.delivery_address')"
                v-model="store.formData.address.deliveryAddress"
                id="address"
            />
        </block>
    </checkout-section>
</template>
