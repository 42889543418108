<script setup lang="ts">
import { useReviews } from '@/composables/useReviews'
import { trans } from 'laravel-vue-i18n'
import Spinner from '@shared/components/Spinner.vue'
import NewReview from '@/components/Products/ProductTabs/NewReview.vue'
import Review from '@/components/Products/ProductTabs/Review.vue'
import LoadMoreButton from '@/components/LoadMoreButton.vue'

const { productSlug } = defineProps<{
    productSlug: string
}>()

const { reviews, loading, nextPageUrl, loadMore } = useReviews(productSlug)
</script>

<template>
    <new-review :productSlug />

    <div v-if="loading" class="flex gap-5 items-center justify-center pt-7">
        <spinner />

        <h2 class="text-lg md:text-xl">{{ trans('product.loading_reviews') }}...</h2>
    </div>

    <h2 v-else-if="reviews.length === 0" class="text-center pt-4 text-xl">
        {{ trans('product.no_reviews') }}
    </h2>

    <div v-else class="space-y-4 mt-5">
        <review v-for="review in reviews" :key="review.id" :review />
    </div>

    <div v-if="nextPageUrl" class="flex justify-center mt-5">
        <load-more-button @click="loadMore" />
    </div>
</template>
