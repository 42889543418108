<script setup lang="ts">
import { Category } from '@shared/types/models'
import ArrowLeftIcon from '@shared/components/Icons/ArrowLeftIcon.vue'

defineProps<{
    category: Category
}>()

const emit = defineEmits<{
    (e: 'closeMenu'): void
}>()
</script>

<template>
    <div class="border-b border-border flex items-center py-1">
        <button @click="emit('closeMenu')" type="button" class="py-3 px-2">
            <arrow-left-icon class="w-5 h-5" />
        </button>

        <div class="flex-1 text-center pr-6">
            <a
                v-if="category"
                class="font-heading text-lg leading-4 py-3 uppercase"
                :href="`/${category.slug}`"
            >
                {{ category.name[$locale] }}
            </a>
        </div>
    </div>
</template>
