export default (str: string, char: string): string => {
    let start = 0
    let end = str.length

    if (char === str) {
        return ''
    }

    while (str[start] === char) {
        start++
    }

    while (str[end - 1] === char) {
        end--
    }

    return str.substring(start, end)
}
