<script setup lang="ts">
import { onMounted } from 'vue'
import { useProductStore } from '@/stores/product'
import { trans } from 'laravel-vue-i18n'
import ProductCard from '@/components/Products/ProductCard.vue'
import Spinner from '@shared/components/Spinner.vue'
import LoadMoreButton from '@/components/LoadMoreButton.vue'
import Message from '@shared/components/Message.vue'

const props = defineProps<{
    categorySlug: string
}>()

const store = useProductStore()

onMounted(() => {
    store.fetchProducts(`/api/products/${props.categorySlug}`)
})
</script>

<template>
    <spinner v-if="store.loading" />

    <message v-else-if="store.products.length === 0">
        {{ trans('product.no_products') }}
    </message>

    <div
        v-else
        class="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-x-0.5 md:gap-x-2 gap-y-4"
    >
        <product-card
            v-for="product in store.products"
            :key="product.id"
            :product
            :category-slug
        />
    </div>

    <div v-if="store.nextPageUrl" class="text-center mt-5">
        <load-more-button
            @click="store.loadMoreProducts"
            :loading="store.loadingMore"
        />
    </div>
</template>
