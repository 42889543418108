<script setup lang="ts">
import { onMounted, onUnmounted } from 'vue'
import Overlay from '@shared/components/Overlay.vue'
import CloseIcon from '@shared/components/Icons/CloseIcon.vue'

defineProps<{
    className?: string
    label?: string
}>()

const emit = defineEmits<{
    (e: 'closed'): void
}>()

onMounted(() => {
    document.body.style.overflow = 'hidden'
})

onUnmounted(() => {
    document.body.style.overflow = ''
})

window.addEventListener(
    'keydown',
    (e: KeyboardEvent) => {
        if (e.key === 'Escape') {
            emit('closed')
        }
    },
    { once: true },
)
</script>

<template>
    <overlay @closed="emit('closed')" />

    <div
        :class="[
            'bg-page fixed top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2',
            'z-50 rounded-md font-main',
            ' ' + (className || ''),
        ]"
    >
        <div class="flex justify-between border-b border-border py-4 px-6">
            <h2 v-if="label" class="text-2xl font-heading text-gray-700">
                {{ label }}
            </h2>

            <button
                @click="emit('closed')"
                type="button"
                class="border border-transparent rounded-full p-1 hover:border-border"
            >
                <close-icon class="w-6 h-6 text-black" />
            </button>
        </div>

        <div class="p-4 lg:px-7 overflow-y-auto max-h-[90vh] md:max-h-[85vh]">
            <slot />
        </div>
    </div>
</template>
