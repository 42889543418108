type SocialLink = {
    name: string
    url: string
    icon: string
}

export default (): SocialLink[] => {
    return [
        {
            name: 'Instagram',
            url: 'https://www.instagram.com/vipysknik_lenty',
            icon: '/storage/icons/instagram.webp',
        },
        {
            name: 'Viber',
            url: 'tel:+30978624094',
            icon: '/storage/icons/viber.webp',
        },
    ]
}
