<script setup lang="ts">
import type { Category } from '@shared/types/models'
import { useMenuStore } from '@/stores/menu'
import { computed } from 'vue'
import MobileNavHeader from '@/components/MobileMenu/MobileNavHeader.vue'
import Banner from '@/components/MobileMenu/Banner.vue'
import NestedLink from '@/components/MobileMenu/NestedLink.vue'

const emit = defineEmits<{
    (e: 'closeMenu'): void
}>()

const props = defineProps<{
    categories: Category[]
    category: Category
}>()

const menuStore = useMenuStore()

const hasBanner = computed(() => props.category.banners?.length)
</script>

<template>
    <div class="sticky bg-page text-font inset-0 z-30 w-sidebar-menu h-full">
        <mobile-nav-header @closeMenu="emit('closeMenu')" :category />
        <banner :category v-if="hasBanner" />

        <div v-if="category.hint?.ru" class="bg-page-second p-3 pt-5">
            <span class="text-md uppercase font-heading font-bold">
                {{ category.hint[$locale] }}
            </span>
        </div>

        <div class="flex flex-col px-3 overflow-y-auto h-[calc(100%-49px)]">
            <nested-link
                v-for="child in categories"
                :key="child.id"
                :category="child"
                @closeMenu="menuStore.isOpen = false"
            />
        </div>
    </div>
</template>
