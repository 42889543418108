<script setup lang="ts">
import { computed } from 'vue'
import { useCartStore } from '@/stores/cart'
import { trans } from 'laravel-vue-i18n'
import Spinner from '@shared/components/Spinner.vue'
import BigButton from '@shared/components/Buttons/BigButton.vue'
import linkTo from '@shared/modules/linkTo'

type Props = {
    withFooter?: boolean
}

withDefaults(defineProps<Props>(), {
    withFooter: true,
})

const store = useCartStore()

const formatPrice = (price: number) => {
    return new Intl.NumberFormat('ru-RU').format(price)
}

const disabled = computed<boolean>(() => {
    for (const item of store.cartItems) {
        if (item.quantity < item.min_order_quantity) {
            return true
        }
    }

    return store.cartItems.length === 0
})
</script>

<template>
    <div>
        <spinner v-if="store.loading" :inline="true" :small="true" />

        <ul
            v-else
            class="mt-3 pt-3 border-t border-border-light"
            :class="{ 'mb-3 pb-3 border-b': withFooter }"
        >
            <li class="flex justify-between text-lg">
                <span class="font-heading">{{ trans('main.sum') }}:</span>
                <b class="font-heading">{{ formatPrice(store.totalPrice) }}.00 ₴</b>
            </li>
        </ul>

        <div v-if="withFooter">
            <big-button
                :href="linkTo('checkout')"
                class="text-sm lg:text-md"
                :disabled
            >
                {{ trans('main.checkout') }}
            </big-button>

            <big-button
                @click="store.isOpen = false"
                :class="[
                    'text-sm lg:text-md mt-2 !text-primary bg-transparent border border-primary',
                    'hover:border-gray-800 hover:!text-gray-800 hover:bg-transparent w-full',
                ]"
                :disabled="store.cartItems.length === 0"
            >
                {{ trans('main.continue_shopping') }}
            </big-button>

            <h3 class="font-heading font-black mt-5 tracking-wide">
                {{ trans('main.we_accept') }}:
            </h3>

            <div class="flex items-center gap-3 mt-3">
                <img src="/storage/icons/visa.webp" class="h-4" />
                <img src="/storage/icons/mastercard.webp" class="h-6" />
            </div>
        </div>
    </div>
</template>
