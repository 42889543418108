import type { MenuItem } from '@shared/types'
import TruckIcon from '@shared/components/Icons/TruckIcon.vue'
import InfoIcon from '@shared/components/Icons/InfoIcon.vue'
import CreditCardIcon from '@shared/components/Icons/CreditCardIcon.vue'
import ShieldCheckIcon from '@shared/components/Icons/ShieldCheckIcon.vue'
import PhoneIcon from '@shared/components/Icons/PhoneIcon.vue'
import linkTo from '@shared/modules/linkTo'

export default (): MenuItem[] => {
    return [
        {
            label: 'main.delivery',
            href: linkTo('delivery'),
            active: isActive('delivery'),
            icon: TruckIcon,
        },
        {
            label: 'main.about_us',
            href: linkTo('about_us'),
            active: isActive('about_us'),
            icon: InfoIcon,
        },
        {
            label: 'main.pay',
            href: linkTo('privacy'),
            active: isActive('privacy'),
            icon: CreditCardIcon,
        },
        {
            label: 'main.guarantees',
            href: linkTo('terms'),
            active: isActive('terms'),
            icon: ShieldCheckIcon,
        },
        {
            label: 'main.contacts',
            href: linkTo('contact-us'),
            active: isActive('contact-us'),
            icon: PhoneIcon,
        },
    ]
}

function isActive(path: string): boolean {
    return window.location.pathname.includes(path)
}
